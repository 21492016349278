$(function(){
// @media screen and (min-width: 768px) と同じ
var mql = window.matchMedia('screen and (min-width: 768px)');    
var printCheck = $('.js_print').css('display') == 'block' //印刷状態をチェック

//::::::::::::::::::::::::::::::::    
// スマホとタブレットでviewportを切替え!
//::::::::::::::::::::::::::::::::

var ua = navigator.userAgent;
var getDevice = (function(){    
    if ((ua.indexOf('iPhone') > 0) || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
        //$('head').prepend('<meta name="viewport" content="width=device-width,initial-scale=1">');
        $('.txt-tellink,.txt_tellink').each(function () {
            var str = $(this).text();
            $(this).html($('<a>').attr('href', 'tel:' + str.replace(/-/g, '')).append(str + '</a>'));
        });
    } else {
        //$('head').prepend('<meta name="viewport" content="width=1240">');
    var head = $('head');
    var headChildren = head.children();
    var childrenLength = headChildren.length;
    for(var i = 0;i < childrenLength;i++){
        var metaName = headChildren.eq(i).attr('name');
//        if(metaName === 'viewport'){
//            headChildren.eq(i).attr('content','width=1240');
//        }
      }
    }
})()

//:::::::::::::::::::::::::::::::
// scrollTop html/bodyの判定
//:::::::::::::::::::::::::::::::
var scrollableElement;
// document.scrollingElementに対応していればそれを使う
if ('scrollingElement' in document) {
  scrollableElement = document.scrollingElement;
}
// IEのとき
else if (/*@cc_on!@*/false || (!!window.MSInputMethodContext && !!document.documentMode)) {
  scrollableElement = document.documentElement;
}
// それ以外
else {
  scrollableElement = document.body;
}
    
//::::::::::::::::::::
// スマホメニューの開閉 
//::::::::::::::::::::   

var menu = $('.menu');
var menuBtn = $('.menu-btn');
var spmenu = $('.spmenu');
var body = $('body');
var link = $('a');
var state = false;
var scrollpos;
var top_btn = $('.top-btn');    
//var headerheight = $("header").outerHeight(true);

//SPmenuを開閉する関数
function SPmenuClose( btn ) {
    if( btn === undefined ){
       btn = '';
       }
    //$('.trial-modal').hide();
    if( btn ){
        if ( $(btn).hasClass('open') ){
            menuBtn.removeClass('open');
            spmenu.removeClass('menuopen');
             //console.log("OPEN!");
            //$('.owl-carousel').trigger('play.owl.autoplay');
            
                if ($(this).scrollTop() > 200) {
            top_btn.addClass('fade-btn');}
        } else {
            menuBtn.addClass('open');
            spmenu.addClass('menuopen');
            top_btn.removeClass('fade-btn');
            //$('.owl-carousel').trigger('stop.owl.autoplay');
            
        }
    } else {
        menuBtn.removeClass('open');
        spmenu.removeClass('menuopen');
    }
}

//ページ内リンクのときはSPメニューを閉じる
var SPmenuLink =  $('.spmenu a[href*="#"]'); 
$( menuBtn.add(SPmenuLink) ).on('click',function () {
    SPmenuClose( this );
})
    
//:::::::::::::::::  
//サブメニューの開閉
//:::::::::::::::::   

//イベント伝播の判定 
if (!mql.matches) {    
var preventEvent = true;
    $(".submenu a").on("click", function () {
        preventEvent = false;
    })  
    
    $(".js_submenu").on("click", function () {
                if(preventEvent){
            event.preventDefault();}
        
        $(this).find(".submenu").slideToggle(300, 'easeInOutSine');
        //$(this).toggleClass('icon-role');
        $(this).toggleClass('icon-more')
        $(this).toggleClass('icon-minus');
    })
  
$(".js_submenu>a").on("click", function () {
preventEvent = true;    
 })  
}else {}
//::::::::::::::::::::    
// スライドtoggle
//::::::::::::::::::::    

$(".js_st").on("click", function () {
    $(this).next().slideToggle(300, 'easeInOutQuad');
})
   
////headerの高さ    
//var height = 75;
//if (mql.matches) {
//    var height = 100;    
//} 
    
////////WPのログインバー
//var WPadminbar = $("#wpadminbar");
//if (WPadminbar.length) {
////$('.menu-btn,.submenu,.l-spmenu').addClass('js_');    
// 
//if (mql.matches){
//$(window).scroll(function () {
//    if ($(this).scrollTop() > 102) {
//        $('header').addClass('fix-header--wp');
//        return false;
//    } else {
//        $('header').removeClass('fix-header--wp');
//        return false;
//    }    
//})    
// 
//}else{
//WPadminbar.css({'position':'fixed','top':'0'});
//$('.menu-btn').css('top','56px');
//$('.l-spmenu').css('top','46px');
//}
//}else{} 
//


 // 初回チェック  
}); //$ふぁんくしょんおわり！    